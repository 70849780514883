@import "style/global.scss";

.input {
    background-color: $soft;
    border-radius: $radius;
    padding: 16px 16px;
    outline: none;
    border: none;
    font-weight: 600;
}

.input::placeholder {
    color: rgb(44, 44, 44);
}
