@import "style/global";

.title {
    text-align: center;
    font-weight: 800;
    font-size: 1.4rem;
}

.panel {
    @extend .shadow;
    background-color: white;
    margin: 15px 0;
    border-radius: 3px;
    padding: 30px 40px;
}

.root {
    min-height: 100vh;
    padding: 20px 0 0 0;
    display: flex;
}

.container {
    max-width: 800px;
    margin: auto;
}

.centered {
    margin: auto;
    width: 100%;
}

.selector {
    margin: 0 0 50px 0;
}

.loginButton {
    margin-right: 10px;
}

.inputs {
    margin: 0 20px 0 0;
}

.input {
    width: 100%;
    margin-bottom: 30px;
}

.lineSeparator {
    background-color: $soft;
    width: 3px;
    border-radius: 99px;
    margin: 0 10px;
    height: 100%;
}

.lineSeparatorHorizontal {
    background-color: $soft;
    width: 100%;
    border-radius: 99px;
    margin: 20px 10px;
    height: 3px;
}

.buttonLoginContainer {
    display: flex;
    justify-content: flex-end;
}

.titleLoginVia {
    color: rgb(71, 71, 71);
    font-weight: 600;
}

.buttonProvider {
    display: block;
    width: 100%;
    max-width: 200px;
    text-align: left;
    margin-top: 20px;
}

.providers {
    padding: 20px 0 30px 0;
}

.spinner {
    text-align: center;
    width: 100%;
    padding: 60px 0;
}

.spinner :global .gg-spinner {
    display: inline-block !important;
}

.errorMessageContainer {
    text-align: center;
    margin: 0 0 20px 0;
}

.errorMessage {
    display: inline-block;
    color: rgb(220, 40, 0);
    background-color: rgba(220, 40, 0, 0.2);
    border-radius: 15px;
    padding: 0px 10px;
}
