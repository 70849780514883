@import "style/global.scss";
.nav {
    position: fixed;
    width: 300px;
    background-color: $background;
    min-height: 100vh;
    top: 0;
    left: 0;
    transition: left 0.3s;
    z-index: 2;
}

.navContainer {
    position: relative;
    min-height: 100vh;
}

.content {
    padding: 30px 30px 30px 30px;
    padding-left: 330px;
    background-color: white;
    min-height: 100vh;
    position: relative;
    left: 0;
    transition: padding-left 0.3s, left 0.3s;
}

@media (max-width: 600px) {
    .content {
        padding: 30px 20px 30px 30px;
    }
}

.containerTop {
    padding: 30px 30px;
}

.title {
    font-weight: 800;
    color: $tertiary;
    background-color: $tertiary-soft;
    font-size: 1rem;
    display: inline-block;
    padding: 0 10px;
    border-radius: 99px;
}

.links {
    margin-top: 40px;
}

.containerBot {
    position: absolute;
    bottom: 20px;
    left: 10px;
    width: 280px;
    opacity: 1;
    transition: opacity 0.3s;
}

.item {
    margin-bottom: 10px;
}

.triggerMenu {
    position: absolute;
    top: 30px;
    right: 10px;
    color: $tertiary;
    background-color: $tertiary-soft;
    border-radius: 99px;
    text-align: center;
    width: 24px;
    height: 24px;
    font-weight: 800;
    font-size: 17px;
    transform: rotate(0);
    transition: transform 0.3s;
    background-repeat: no-repeat;
    background-size: 70% 70%;
    background-position: center;
    cursor: pointer;

    &:hover {
        background-color: rgba(27, 48, 66, 0.3);
    }
}
