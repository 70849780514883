$background: rgb(244, 252, 250);
$soft: rgb(245, 245, 245);
$primary: #b3482f;
$secondary: #01a89d;
$secondary-soft: rgba(1, 168, 157, 0.1);
$tertiary: #1b3042;
$tertiary-soft: rgba(27, 48, 66, 0.05);
$radius: 10px;

.shadow {
    -webkit-box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.02);
    -moz-box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.02);
    box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.02);
}

._title {
    font-size: 1rem;
    font-weight: 800;
}

.shadow-05 {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
