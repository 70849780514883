@import "style/global.scss";

.select {
    background-color: $soft;
    border-radius: $radius;
    outline: none;
    display: block;
    border: none;
    font-weight: 600;
}
