@import "style/global.scss";

.rootElm {
    position: relative;
}

.inputElement {
    background-color: $soft;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 40px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.07);
    }
}

.listSelected {
    font-size: 0.7rem;
    padding: 7px 40px 0 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.elementValue {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 2px 25px 2px 5px;
    border-radius: 7px;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 7px 7px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

.closeElm {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 4px;
}

.selectMore {
    position: absolute;
    right: 20px;

    > div {
        height: 20px;
        width: 20px;
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: 80% 80%;
        -webkit-mask-position: center;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-size: 80% 80%;
        background-color: rgba(0, 0, 0, 0.7);
        transition: transform 0.3s;
    }
}

.dropdown {
    @extend .shadow;
    position: absolute;
    top: 45px;
    background-color: white;
    z-index: 1;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    overflow: hidden auto;
    max-height: 200px;
    min-width: 100%;
    padding: 5px 0;
}

.itemSelectable {
    display: flex;
    position: relative;
    align-items: center;
    padding: 2px 10px 2px 30px;
    font-size: 0.8rem;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

.isSelected {
    position: absolute;
    left: 10px;
    color: $secondary;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
