@import "style/global.scss";

.root {
    display: block;
    padding: 5px 10px;
    margin: 0 -10px;
    cursor: pointer;
    border-radius: 10px;
    background-color: none;
    transition: background-color 0.3s;

    &:hover {
        background-color: $tertiary-soft;
    }
}

.logo {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.text {
    display: inline-block;
    vertical-align: middle;
    font-weight: 800;
    color: $tertiary;
    margin-left: 15px;
}

.image {
    display: inline-block;
    height: 0.6rem;
    width: 20px;
}
