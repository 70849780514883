@import "style/global.scss";

.root {
    background-color: white;
    border-radius: 1599px;
    padding: 10px 10px;

    &:hover {
        @extend .shadow;
    }
}

.initials {
    height: 60px;
    width: 60px;
    position: relative;
    background-color: $secondary;
    color: white;
    border-radius: 999px;
}

.initialsLetters {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    font-size: 1rem;
    font-weight: 800;
    text-transform: uppercase;
    transform: translate(-50%, -50%);
}

.arrow {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80% 80%;
    transform: rotate(-90deg);
}

.name {
    font-weight: 800;
    font-size: 0.9rem;
}

.containerTagType {
    margin-top: -8px;
}

.tagType {
    font-size: 0.6rem;
    color: $secondary;
    background-color: $background;
    display: inline-block;
    border-radius: 99px;
    padding: 1px 8px;
    font-weight: 800;
}
