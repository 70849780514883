@import "style/global.scss";

.base_root {
    position: relative;
    padding: 0;
    border-radius: 10px;
}

.root {
    @extend .base_root;
    background-color: $soft;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

.root_primary {
    @extend .base_root;
    background-color: $secondary-soft;
    color: $secondary;

    &:hover {
        background-color: $background;
    }
}

.titleDiv {
    height: 40px;
    padding: 0 15px;
    position: relative;
    cursor: pointer;
}

.title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 600;
    user-select: none;
}

.elements {
    @extend .shadow;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    position: absolute;
    top: 50px;
    padding: 5px 0;
    background-color: white;
    min-width: 100%;
    overflow: hidden;
    z-index: 1;
}

.dropElement {
    padding: 3px 15px;
    background-color: none;
    cursor: pointer;

    &:hover {
        background-color: $soft;
    }
}

.arrow_base {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 20px;
    width: 20px;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 80% 80%;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 80% 80%;
    transition: transform 0.3s;
}

.arrow {
    @extend .arrow_base;
    background-color: rgba(0, 0, 0, 0.4);
}

.arrow_primary {
    @extend .arrow_base;
    background-color: $secondary;
}
