@import "style/global";

.root {
    min-height: 100vh;
    display: flex;
}

.vcentered {
    margin: auto;
    width: 100%;
}

.container {
    @extend .shadow;
    background-color: white;
    padding: 20px 50px 0 50px;
    border-radius: 15px;
    max-width: 1000px;
    margin: auto;
}

.title {
    font-weight: 800;
    margin: 10px 0;
    text-align: center;
    font-size: 1.1rem;
}

.fields {
    padding: 20px 0;
}

.field {
    width: 100%;
    margin-top: 15px;
    font-size: 0.6rem;
}

.goButtonContainer {
    text-align: center;
    margin-top: 20px;
}

.goButton {
    display: inline-block;
    padding: 10px 60px;
    background-color: $primary;
    color: white;
    border-radius: 30px 30px 0 0;
    font-weight: 700;
    font-size: 0.8rem;
    cursor: pointer;

    > .buttonText {
        display: inline-block;
        vertical-align: middle;
    }

    > .arrowButton {
        display: inline-block;
        vertical-align: middle;
        height: 25px;
        width: 25px;
        margin-left: 15px;
        border-radius: 99px;
        background-color: rgba(0, 0, 0, 0.2);
        background-size: 60% 60%;
        background-position: center;
        background-repeat: no-repeat;
        transition: background-color 0.3s;
    }
}

.goButton:hover > .arrowButton {
    background-color: rgba(0, 0, 0, 0.3);
}

.sectionTitle {
    text-transform: capitalize;
    display: inline-block;
    vertical-align: middle;
}

.titleLogo {
    height: 0.8rem;
    width: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}
