@import "style/global.scss";

.removeDefaultButtonStyle {
    display: inline-block;
    font-family: inherit;
    background: none;
    border: none;
    outline: none;
}

.baseButton {
    @extend .removeDefaultButtonStyle;
    padding: 8px 16px;
    border-radius: $radius;
    font-weight: 600;
    cursor: pointer;
}

.softButton {
    @extend .baseButton;
    color: rgb(77, 77, 77);
    transition: background-color 0.3s, color 0.3s;
    background-color: transparent;
}

.softButton:hover {
    // background-color: $soft;
    color: black;
}
.softButtonActive {
    background-color: $soft;
    color: black;
}

.button {
    @extend .baseButton;
    color: rgb(77, 77, 77);
    padding: 6px 10px 6px 20px;
    transition: background-color 0.3s, color 0.3s;
}

.button:hover {
    color: black;
}

.primaryButton {
    @extend .button;
    color: white;
    background-color: $primary;
}

.tertiaryButton {
    @extend .button;
    color: white;
    background-color: $tertiary;

    &:hover {
        color: white;
        background-color: #194265;
    }
}

.imgContainer {
    width: 25px;
    height: 25px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 999px;
    margin: 0 0 0 25px;
    position: relative;
}

.primaryButtonActive {
    @extend .primaryButton;
    background-color: rgb(204, 92, 67);
}

.primaryButton:hover {
    @extend .primaryButtonActive;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    font-size: 15px;
}
